import {
    toggleClass,
    toggleAriaExpanded
} from './helpers.js';

const mapShelfToggle = document.getElementById('mapShelfToggle');
const mapShelf = document.getElementById('mapShelf');

if ( mapShelfToggle ) {
    mapShelfToggle.addEventListener('click', function(event) {
        event.preventDefault();

        toggleClass(mapShelf, 'open');
        toggleAriaExpanded(mapShelf);

    });

}
