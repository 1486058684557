import * as Highcharts from 'highcharts/highmaps';
import 'highcharts/highcharts-more';
import 'highcharts/modules/map';
import './highcharts/world-palestine-highres';

jQuery.noConflict();
jQuery(document).ready(function(jQuery) {

    // Instanciate the map
    Highcharts.mapChart('special-nsliy-map-container', {
        chart: {
            borderWidth: 0
        },

        colors: ['#D91F39', '#127CC0', '#F2822D', '#FECC2F', '#1CADD3', '#75BE4A', '#0D7335', '#b300b3', '#ff8da1'],

        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },

        legend: {
            enabled: true
        },

        mapNavigation: {
            enabled: true,
            enableDoubleClickZoomTo: true,
            enableMouseWheelZoom: false
        },

        plotOptions: {
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function() {
                            window.open(this.options.value)
                        }
                    }
                }

            }
        },


        series: [{
            name: 'Country',
            showInLegend: false,
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            enableMouseTracking: false
        }, {
            name: 'Russian',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['ru', "/stories?id=68"],
                ['ee', "/stories?id=68"],
                ['md', "/stories?id=68"],
                ['lv', "/stories?id=68"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }, {
            name: 'United States',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['us', "/stories?id=global"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span>Click to read impact stories by NSLI-Y Alumni from around the world!</span>',
            }
        }, {
            name: 'Chinese',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['cn', "/stories?id=64"],
                ['tw', "/stories?id=64"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }, {
            name: 'Hindi',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['in', "/stories?id=65"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }, {
            name: 'Arabic',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['jo', "stories?id=62"],
                ['ma', "stories?id=62"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }, {
            name: 'Korean',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['kr', "/stories?id=66"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }, {
            name: 'Persian',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['tj', "stories?id=67"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }, {
            name: 'Turkish',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['tr', "stories?id=69"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }, {
            name: 'Indonesian',
            mapData: Highcharts.maps['custom/world-palestine-highres'],
            allAreas: false,
            data: [
                ['id', "/stories?id-63"],
            ],
            dataLabels: {
                enabled: false,
                color: '#FFFFFF',
                formatter: function () {
                    if (this.point.value) {
                        return this.point.name;
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span><b>{point.name}</b><br />Click to read stories from NSLI-Y participants learning {series.name}!</span>',
            }
        }]
    });

});
